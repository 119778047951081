import React from "react";
import { cn } from "../helpers";
const Button = ({
  title = "",
  label,
  type,
  disabled,
  outline,
  small,
  className,
  onClick,
  fullWidth,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      title={title}
      className={cn(
        `disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition flex items-center justify-center gap-2 bg-blue-500 text-white py-1.5`,
        outline && "bg-white text-slate-700",
        small ? "text-sm" : "text-sm px-3",
        fullWidth && "w-full",
        className,
      )}
      formNoValidate
    >
      {label}
    </button>
  );
};

export default Button;
