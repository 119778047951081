import { Navigate, useRoutes } from "react-router-dom";
import LoginPage from "./pages/login";
import Layout from "./layouts";
import RouteList from "./RouteList";
import Cookies from "js-cookie";

const Authenticated = ({ component }) => {
  const auth = Cookies.get("presensi_access_token");

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return component;
};

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Authenticated component={<Layout />} />,
      name: "Beranda",
      children: RouteList,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <div>Not Found</div>,
    },
  ]);

  return routes;
}
