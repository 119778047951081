import { Subject } from "rxjs";

const sidebarToggleSubject = new Subject(false);

export const sidebarToggleService = {
  toggle: (newValue) => {
    const currentValue = sidebarToggleSubject.value;
    sidebarToggleSubject.next(
      newValue !== undefined && newValue !== null ? newValue : !currentValue
    );
  },
  onToggle: () => sidebarToggleSubject.asObservable(),
};
