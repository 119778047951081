import axios from "axios";
import Cookies from "js-cookie";

// Create Axios instance
const axiosInstance = axios;

let isRefreshing = false;
let failedQueue = [];

// Helper to process the queue of pending requests
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("presensi_access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If 401, attempt token refresh
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        // Queue the request while token is being refreshed
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        // Call refresh token endpoint
        const refreshToken = Cookies.get("presensi_refresh_token");
        const response = await axios.post(
          `${import.meta.env.VITE_URL_SSO}/auth/refresh-token`,
          {
            refresh_token: refreshToken,
          },
        );
        const newAccessToken = response.data.data.access_token;
        Cookies.set("presensi_access_token", newAccessToken);
        Cookies.set("presensi_refresh_token", response.data.data.refresh_token);

        processQueue(null, newAccessToken);
        isRefreshing = false;

        // Retry the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        isRefreshing = false;
        // Optionally log out or redirect to login
        Cookies.remove("presensi_access_token");
        // localStorage.removeItem("refresh_token");

        return Promise.reject(refreshError);
      }
    }

    // For other errors, reject as usual
    return Promise.reject(error);
  },
);

export default axiosInstance;
