import { useCallback } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from '../helpers'

const Modal = ({isOpen, onClose, children, isDelete = false}) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose])
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Dialog static open={isOpen} onClose={handleClose} className="relative z-50 showModal">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/30"
            />
            <div className={cn("fixed inset-0 w-screen p-4", !isDelete && "overflow-y-scroll")}>
              <div className='min-h-full flex items-center justify-center'>
                <DialogPanel
                  as={motion.div}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  className={cn("max-w-lg space-y-4 bg-white px-5 py-5 rounded-lg", !isDelete && "w-full")}
                >
                  {children}
                </DialogPanel>
              </div>
            </div>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  )
}

Modal.displayName = "Modal";

export default Modal;
