import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import weekday from 'dayjs/plugin/weekday';
import { cn, encryptStorage } from "../helpers";
dayjs.extend(weekday);

export default function DatePicker({
  name,
  label,
  className,
  required,
  showShortcuts = false,
  values,
  useRange = true,
  single = false,
  onChange,
  displayFormat = "DD/MM/YYYY",
  errors,
  errorCustomId = "",
  placeholder = " ",
  disabled,
  minDate = null,
  maxDate = null,
  popoverDirection = "",
  primaryColor = "blue",
  separator = "~",
}) {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateValue, setDateValue] = useState({
    minDate: null,
    maxDate: null,
  });
  useEffect(() => {
    let subscribe = false;
    if (!subscribe) {
      const yearData = encryptStorage.getItem("fin_year_data");
      let minDateYear = yearData
        ? new Date(`${yearData}-01-01`)
        : minDate
        ? new Date(minDate)
        : null;
      let maxDateYear = yearData
        ? new Date(`${yearData}-12-31`)
        : maxDate
        ? new Date(maxDate)
        : null;
      setDateValue({ minDate: minDateYear, maxDate: maxDateYear });
    }
    setValue(values);

    return () => {
      subscribe = true;
    };
  }, [setValue, values, setDateValue, minDate, maxDate]);
  return (
    <>
      <div className="input-group-date relative">
        <Datepicker
          i18n="id"
          popoverDirection={popoverDirection}
          inputId={name}
          minDate={dateValue.minDate}
          maxDate={dateValue.maxDate}
          placeholder={placeholder}
          useRange={useRange}
          showShortcuts={showShortcuts}
          configs={{
            shortcuts: {
              today: {
                text: "Hari ini",
                period: {
                  start: dayjs().format("YYYY-MM-DD"),
                  end: dayjs().format("YYYY-MM-DD"),
                },
              },
              yesterday: "Kemarin",
              thisWeek: {
                text: "Minggu ini",
                period: {
                  start: dayjs().startOf("week").format("YYYY-MM-DD"),
                  end: dayjs().endOf("week").format("YYYY-MM-DD"),
                },
              },
              lastWeek: {
                text: "Minggu lalu",
                period: {
                  start: dayjs().startOf("week").weekday(-7).format("YYYY-MM-DD"),
                  end: dayjs().startOf("week").weekday(-1).format("YYYY-MM-DD"),
                },
              },
              past: (period) => `${period} hari terakhir`,
              currentMonth: "Bulan ini",
              pastMonth: "Bulan lalu",
              thisYear: {
                text: "Tahun ini",
                period: {
                  start: dayjs().startOf("year").format("YYYY-MM-DD"),
                  end: dayjs().endOf("year").format("YYYY-MM-DD"),
                },
              },
              lastYear: {
                text: "Tahun lalu",
                period: {
                  start: dayjs()
                    .startOf("year")
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                  end: dayjs().startOf("year").subtract(1).format("YYYY-MM-DD"),
                },
              },
            },
          }}
          separator={separator}
          primaryColor={primaryColor}
          containerClassName={"datepicker"}
          inputClassName={twMerge(
            "outline-none input-date",
            values?.startDate ? "has-value" : "",
            (errors?.[name] || errorCustomId)
              ? "border-[1.5px] border-red-500"
              : "border-[1.5px] border-gray-300",
            disabled ? "bg-gray-200" : "bg-transparent",
            disabled &&
              ![null, "", " ", "undefined"].includes(values?.startDate) &&
              "disabled-value",
            className
          )}
          disabled={disabled}
          value={value}
          displayFormat={displayFormat}
          onChange={onChange}
          startFrom={values?.startDate}
          asSingle={single}
        />
        <label
          className={twMerge(
            `input-date-label`,
            (errors?.[name] || errorCustomId) ? "text-rose-500" : "text-zinc-700"
          )}
          htmlFor={name}
        >
          {label || ""}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      </div>
        {(errors?.[name] || errorCustomId) && (
          <p className={cn("text-rose-500 text-sm", errorCustomId && "mt-2", errors?.[name] && "mt-[-0.75rem]")}>
            {errors?.[name]?.startDate
              ? errors?.[name]?.startDate?.message
              : errors?.[name]?.message || ""}
            {errorCustomId?.message?.toString()}
          </p>
        )}
    </>
  );
}
