// components/RouteChangeListener.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginationService } from "../state/pagination";

const RouteChangeListener = () => {
  const location = useLocation();

  useEffect(() => {
    // You can perform any other logic here, such as triggering side effects
    paginationService.setPagination(null);
  }, [location]); // Dependency on location to detect route changes

  return null; // This component doesn't render anything, just listens for route changes
};

export default RouteChangeListener;
