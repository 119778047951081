import React from "react";
import Main from "./main";
import Sidebar from "./sidebar";
import { MotionConfig } from "framer-motion";

export default function Layout() {
  return (
    <MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.4 }}>
      <section className="app">
        <Sidebar />
        <Main />
      </section>
    </MotionConfig>
  );
}
