import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import { sidebarToggleService } from "../state/sidebarToggle";
import { cn } from "../helpers";

export default function Main() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    const subscription = sidebarToggleService.onToggle().subscribe(() => {
      setIsCollapsed((prevIsOpen) => !prevIsOpen);
    });

    return () => subscription.unsubscribe();
  }, []);
  return (
    <main
      aria-label="Main-Content"
      className={cn(
        "w-full bg-[var(--bg-body)] grid grid-rows-[90px_1fr] gap-0 min-h-screen h-max",
        !isCollapsed && "active"
      )}
    >
      <Header />
      <div className="relative z-0">
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
    </main>
  );
}
