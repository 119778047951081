import React, { useState } from "react";
import LOGIN from "../../assets/images/background-merge.png";
import LOGO from "../../assets/images/logo-YPL.png";
import { Button, Input } from "../../components";
import { NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API, encryptStorage } from "../../helpers";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const schema = yup
  .object({
    username: yup.string().required("Masukkan kode pengguna anda"),
    password: yup.string().required("Masukkan kata sandi anda"),
  })
  .required();

export default function LoginPage() {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    showPassword: false,
    loading: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (login.loading) return false;
    setLogin((prev) => ({ ...prev, loading: true }));
    axios
      .post(`${API.sso}/auth/login`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        validateStatus: null,
      })
      .then((res) => {
        if (res?.data?.code !== 200) {
          toast.error(res?.data?.message || "Internal server error");
          return;
        }
        toast.success(res?.data?.message);
        let dataResponse = res?.data?.data;
        const { access_token, refresh_token, expires_in } =
          dataResponse?.tokens;
        const miliseconds = parseInt(expires_in) * 1000;
        const inOneHour = new Date(new Date().getTime() + miliseconds);
        Cookies.set("presensi_access_token", access_token, {
          expires: inOneHour,
        });
        Cookies.set("presensi_refresh_token", refresh_token);
        axios
          .get(`${API.sso}/auth/profile`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((res) => {
            setLogin((prev) => ({ ...prev, loading: false }));
            let data = res?.data?.data;
            const roles = data?.roles || [];
            encryptStorage.setItem("presensiRoles", roles);
            const { roles: _, ...rest } = data;
            encryptStorage.setItem("presensiUser", rest);
            navigate("/");
          })
          .catch((err) => {
            console.log({ errProfile: err });
            setLogin((prev) => ({ ...prev, loading: false }));
            const messages = Object.prototype.hasOwnProperty.call(
              err?.response?.data,
              "message",
            )
              ? err?.response?.data?.message
              : "Maaf terjadi kesalahan internal";
            toast.error(messages);
          });
      })
      .catch((err) => {
        console.log({ errLogin: err });
        toast.error("Internal client error");
      })
      .finally(() => setLogin((prev) => ({ ...prev, loading: false })));
  };

  return (
    <section
      aria-label="Login"
      className="flex h-dvh justify-center items-center"
    >
      <div className="flex-[50%] flex flex-col gap-6 justify-center px-32 py-10">
        <div className="text-left w-full flex flex-col gap-1">
          <h3 className="text-xl font-semibold leading-7">Masuk Akun</h3>
          <h5 className="text-base font-medium text-gray-500">
            Masukkan kode pengguna dan kata sandi untuk mengakses akun anda.
          </h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <Input
            id="username"
            {...register("username")}
            errors={errors}
            autoComplete="off"
            type="text"
            label="Pengguna"
          />
          <div className="flex flex-col gap-2.5">
            <Input
              id="password"
              {...register("password")}
              type={login.showPassword ? "text" : "password"}
              label="Kata Sandi"
              errors={errors}
            />
            <div className="flex justify-between gap-1">
              <div className="flex">
                <input
                  id="hs-toggle-password-checkbox"
                  type="checkbox"
                  onChange={(e) =>
                    setLogin({
                      ...login,
                      showPassword: e.target.checked || false,
                    })
                  }
                  className="shrink-0 border-gray-50000 text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                />
                <label
                  htmlFor="hs-toggle-password-checkbox"
                  className="text-sm text-gray-500 ms-1.5 font-medium dark:text-neutral-400"
                >
                  Tampilkan sandi
                </label>
              </div>
              <NavLink
                to="/forgot-password"
                className="text-sm font-medium text-blue-500 underline"
              >
                Lupa kata sandi?
              </NavLink>
            </div>
          </div>
          <Button
            label="Masuk Akun"
            disabled={login.loading || false}
            className="py-3 text-sm font-medium"
            type="submit"
          />
        </form>
      </div>
      <div className="flex flex-[50%] h-full">
        <div className="relative">
          <img
            src={LOGIN}
            alt="Login Image"
            className="object-cover size-full rounded-bl-[150px]"
          />
          <img
            src={LOGO}
            width={300}
            height={300}
            alt="Logo Image"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div>
    </section>
  );
}
