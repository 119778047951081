import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  UserRound,
  ChevronDown,
  RectangleEllipsis,
  LogOut,
  UserCog,
} from "lucide-react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { encryptStorage } from "../helpers";
export default function DropdownUser() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  const dropdownRef = useRef(null);
  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);
  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const userDetail = encryptStorage.getItem("presensiUser");
    setUser(userDetail);
  }, [setUser])

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        type="button"
        className="border border-gray-200 rounded-md px-3 py-[0.55rem] leading-4 flex gap-2 items-center cursor-pointer"
      >
        <UserRound size={18} />
        <div className="w-16 truncate">
          <span>{user?.name || ""}</span>
        </div>
        <ChevronDown
          size={18}
          className={clsx("duration-300", {
            "rotate-180": isOpen,
          })}
        />
      </button>
      <motion.div
        initial={"closed"}
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: {
            opacity: 1,
            zIndex: 10,
            y: 0,
            transition: {
              duration: 0.3,
            },
          },
          closed: {
            opacity: 0,
            zIndex: -10,
            y: -155,
            transition: {
              duration: 0.3,
            },
          },
        }}
        className="absolute top-10 right-0 bg-white shadow w-64 truncate rounded flex flex-col gap-0"
      >
        <figure className="flex gap-2 items-center border-b border-gray-200 pb-3 px-4 py-3">
          <div className="rounded-md size-10 bg-slate-500 flex items-center justify-center text-white px-2">
            {user?.name?.split(" ")?.map((val) => val.charAt(0))?.slice(0, 2) || ""}
          </div>
          <div className="flex flex-col gap-0 items-start justify-start">
            <span className="text-black text-sm font-semibold whitespace-normal line-clamp-1">
              {user?.name || ""}
            </span>
            <span className="text-slate-500 text-sm font-medium wihtitespace-nowrap">
              {user?.email ? user?.email : user?.username}
            </span>
          </div>
        </figure>
        <NavLink
          to="/profie"
          className="flex gap-4 items-center whitespace-nowrap px-4 py-3 hover:bg-gray-200"
        >
          <UserCog size={18} className="text-slate-500" />
          <span className="font-medium text-sm">Pengaturan akun</span>
        </NavLink>
        <NavLink
          to="/settings"
          className="flex gap-4 hover:bg-gray-200 items-center whitespace-nowrap px-4 py-3"
        >
          <RectangleEllipsis size={18} className="text-slate-500" />
          <span className="font-medium text-sm">Ganti kata sandi</span>
        </NavLink>
        <NavLink
          to="/login"
          className="flex gap-4 hover:bg-gray-200 items-center whitespace-nowrap px-4 py-3"
        >
          <LogOut size={18} className="text-slate-500" />
          <span className="font-medium text-sm">Keluar</span>
        </NavLink>
      </motion.div>
    </div>
  );
}
