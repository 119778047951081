import { BehaviorSubject } from 'rxjs';
import { from, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { API, encryptStorage } from '../helpers';
import axios from '../api';

const unitListSubject = new BehaviorSubject([]);
const selectedUnitSubject = new BehaviorSubject(null);

export const unitService = {
  // Fetch units from the API
  fetchUnits: async () => {
    // Replace this with your actual API call
    const apiCall = await axios.post(`${API.presensi}/unit/list`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }).then(res => res.data);
    let unitsList = [];
    const user = encryptStorage.getItem("presensiUser");
    let unitId = user?.unit_kerja || null;
    from(apiCall?.data || [])
      .pipe(
        tap(units => unitsList.push({label:units?.name, value:units?.id_unit_kerja, perwakilanId: units?.id_perwakilan})),
        catchError(error => {
          console.error("Error fetching units:", error);
          return of([]); // Return an empty list on error
        })
      )
      .subscribe();

      const selected = unitsList?.find(u => u.value === unitId) || {};
      unitListSubject.next(unitsList)
      selectedUnitSubject.next(selected);
    },
  getUnitList: () => {
    return unitListSubject.value;
  },

  // Observable for unit list
  unitList$: unitListSubject.asObservable(),

  // Observable for selected unit
  selectedUnit$: selectedUnitSubject.asObservable(),

  // Function to select a unit
  selectUnit: (unit) => {
    const unitList = unitListSubject.value;
    const selected = unitList?.find(u => u.value === unit) || {};
    selectedUnitSubject.next(selected);
  },
  getSelectedUnit: () => {
    return selectedUnitSubject.value;
  },
};
