import { lazy } from "react";

// import DashboardPage from "./pages/dashboard";
const DashboardPage = lazy(() => import("./pages/dashboard"));
const WorkingTimePage = lazy(() => import("./pages/working-time"));
const SchedulePage = lazy(() => import("./pages/schedule"));
const CalendarPage = lazy(() => import("./pages/calendar"));
const MasterWorkerTypePage = lazy(() => import("./pages/master/workerType"));
const MasterOvertimePage = lazy(() => import("./pages/master/overtime"));
const MasterShiftPage = lazy(() => import("./pages/master/shift"));
const StaffPage = lazy(() => import("./pages/staff/index"));
const OvertimePage = lazy(() => import("./pages/overtime"));
const ShiftSchedulePage = lazy(() => import("./pages/shiftSchedule"));
const AttendancePage = lazy(() => import("./pages/attendance"));

const RouteList = [
  {
    path: "/",
    name: "Beranda",
    element: <DashboardPage />,
  },
  {
    path: "/beranda",
    name: "Beranda",
    element: <DashboardPage />,
  },
  {
    path: "/kalender",
    name: "Kalender",
    element: <CalendarPage />,
  },
  {
    path: "/pegawai",
    name: "Pegawai",
    element: <StaffPage />,
  },
  {
    path: "/lembur",
    name: "Lembur",
    element: <OvertimePage />,
  },
  {
    path: "/jam-kerja",
    name: "Jam Kerja",
    element: <WorkingTimePage />,
  },
  {
    path: "/jadwal-kerja",
    name: "Jadwal Kerja",
    element: <SchedulePage />,
  },
  {
    path: "/jam-kerja-shift",
    name: "Jam Kerja Shift",
    element: <MasterShiftPage />,
  },
  {
    path: "/jadwal-kerja-shift",
    name: "Jadwal Kerja Shift",
    element: <ShiftSchedulePage />,
  },
  {
    path: "/jenis-pekerjaan",
    name: "Jenis Pekerjaan",
    element: <MasterWorkerTypePage />,
  },
  {
    path: "/ketentuan-lembur",
    name: "Ketentuan Lembur",
    element: <MasterOvertimePage />,
  },
  {
    path: "/kehadiran",
    name: "Kehadiran",
    element: <AttendancePage />,
  }
];

export default RouteList;
