import { useLocation, useParams } from "react-router-dom";
import RouteList from "../RouteList";
const useBreadcumbs = () => {
  const currentLocation = useLocation().pathname;
  const getRouteName = ({ pathname, routes, params = false }) => {
    const currentRoute = routes?.find(
      (route) =>
        (params ? `${route.path.replace(":id", "")}${params}` : route.path) ===
        pathname,
    );
    return currentRoute ? currentRoute.name : "";
  };

  const getBreadcrumbs = (location, routeList) => {
    const breadcrumbs = [];
    const { id } = useParams();
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName({
        pathname: currentPathname,
        routes: routeList,
        params: id,
      });
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation, RouteList);

  return breadcrumbs;
};

export default useBreadcumbs;
