import React, { useCallback, useEffect, useState } from "react";
import useBreadcumbs from "../hooks/breadcumbs";
import { NavLink } from "react-router-dom";
import { Home } from "lucide-react";
import { Each, Show, Select } from "../components";
import DropdownUser from "./dropdownUser";
import { unitService } from "../state/units";
import { paginationService } from "../state/pagination";

export default function Header() {
  const [detail, setDetail] = useState({
    unitList: [],
    unit: {},
    time: "",
    pagination: <></>
  })
  const breadcumbs = useBreadcumbs();

  const handleSelectUnit = useCallback((unit) => {
    unitService.selectUnit(unit?.value || "");
  },[])

  useEffect(() => {
    unitService.fetchUnits(); // Fetch units on mount

    const subscription = unitService.unitList$.subscribe((val) => setDetail((prev) => ({...prev, unitList: val})));
    const subscription2 = unitService.selectedUnit$.subscribe((val) => setDetail((prev) => ({...prev, unit: val})));
    const subscription3 = paginationService.pagination$.subscribe((val) => setDetail((prev) => ({...prev, pagination: val})));
    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, [setDetail]);

  useEffect(() => {
    let subsribe = false;
    if(!subsribe){
      const date = new Date();
      const hours = date.getHours();
      if (hours < 12) {
        setDetail((prev) => ({...prev, time: "Pagi"}));
      } else if (hours < 15) {
        setDetail((prev) => ({...prev, time: "Siang"}));
      } else if (hours < 18) {
        setDetail((prev) => ({...prev, time: "Sore"}));
      } else {
        setDetail((prev) => ({...prev, time: "Malam"}));
      }
      subsribe = true
    }

    return () => subsribe = true
  }, [setDetail])
  return (
    <section aria-label="Header" className="grid grid-rows-[50px_1fr] gap-0 sticky top-[0] z-10">
      <section
        aria-label="TopHeader"
        className="flex justify-between items-center gap-2 bg-white px-5 py-2"
      >
        <h1 className="text-xl font-semibold text-slate-700">Selamat {detail?.time}</h1>
        <div className="flex gap-3 items-center justify-center">
          {/* <div className="min-w-64 w-full">
            <Select
              padding="px-[0.8rem] py-0"
              border="border border-gray-200"
              value={detail?.unit || {}}
              onChange={handleSelectUnit}
              name={"unit"}
              options={detail?.unitList || []}
            />
          </div> */}
          <h3 className="text-base font-medium border border-gray-200 px-1 py-1.5 rounded-md">{detail?.unit?.value || ""}</h3>
          <DropdownUser />
        </div>
      </section>
      <section
        aria-label="Breadcrumb"
        className="flex justify-between items-center px-5 py-2 gap-2 bg-white border-t border-b border-gray-200"
      >
        <div className="flex justify-start items-center gap-2">
          <NavLink to={"/"} className="text-[var(--color-purple)]" title="Beranda">
            <Home size={18} />
          </NavLink>
          <Each
            of={breadcumbs || []}
            render={(data) => (
              <>
                <span className="text-slate-500">/</span>
                <Show>
                  <Show.When isTrue={data?.active}>
                    <span className="text-slate-500">{data?.name ?? ""}</span>
                  </Show.When>
                  <Show.Else>
                    <NavLink to={data?.pathname ?? ""} className="text-blue-500">
                      {data?.name ?? ""}
                    </NavLink>
                  </Show.Else>
                </Show>
              </>
            )}
          />
        </div>
        {detail.pagination}
      </section>
    </section>
  );
}
