import { forwardRef, useState } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
const InputFile =
  (
    {
      id,
      label,
      className,
      autoComplete = "off",
      disabled,
      required,
      onChange,
      value,
      errors,
      placeholder = "",
      iconRight = <></>,
      fileName,
      ...props
    }
  ) => {
    return (
      <div className="w-full relative">
        <input
          type="file"
          id={id}
          disabled={disabled}
          autoComplete={autoComplete}
          name={id}
          value={value || ""}
          className="peer absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={onChange}
          {...props}
        />

        <label
          htmlFor={id}
          className={twMerge("absolute left-4 top-3 z-[1] peer-focus:left-3 peer-focus:bg-white peer-focus:px-1.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:top-[-0.625rem] peer-focus:text-sm peer-focus:text-[var(--color-primary)] transition-all text-sm", errors?.[id] ? "text-rose-500" : "text-zinc-700 dark:text-white",
            fileName != '' && "top-[-0.625rem] bg-white px-1.5 text-[var(--color-primary)]"
          )}
        >
          {label}{" "}
          {required && <span className="text-rose-500">*</span>}
        </label>

        <div className={twMerge("peer-focus:border peer-focus:border-[var(--color-primary)] peer-focus:outline-none text-zinc-900 border  rounded-md px-3", fileName ? "py-2" : "py-5", errors?.[id] ? "border-rose-500" : fileName != "" ? "border-[var(--color-primary)]" : "border-gray-300")}>
          {fileName ? fileName : ''}
        </div>
        {errors?.[id] && (
          <p className="text-rose-500 text-sm mt-2">
            {errors?.[id]?.message?.toString()}
          </p>
        )}
      </div>
    );
  }

InputFile.displayName = "InputFile";

export default InputFile;
