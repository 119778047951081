import Modal from "./Modal";
import Button from "./Button";
import { Dialog } from "@headlessui/react";
import { TriangleAlert } from "lucide-react";

const ConfirmDelete = ({ isOpen, isLoading, onClose, onDelete, title = "Hapus Data", content = "Apakah anda yakin? Setelah dihapus anda tidak dapat mengembalikannya." }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isDelete>
      <div className="sm:flex sm:items-start w-[400px]">
        <div className=" mx-auto flex h-12 w-12 flex-shrink-0 rounded-full items-center justify-center bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <TriangleAlert size={18} className="text-red-600" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base text-gray-900 font-semibold leading-6"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-700 line-clamp-3">
              {content}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          type="button"
          disabled={isLoading || false}
          label="Hapus"
          onClick={onDelete}
          className="bg-red-600"
        />
        <Button
          type="button"
          outline
          label={<span className="text-gray-900">Batal</span>}
          disabled={isLoading || false}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

ConfirmDelete.displayName = "Confirm Delete";

export default ConfirmDelete;
