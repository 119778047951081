import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
const Input = forwardRef(
  (
    {
      id,
      label,
      type,
      className,
      autoComplete = "off",
      disabled,
      required,
      errors,
      errorCustomId = "",
      placeholder = "",
      iconRight = <></>,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="w-full relative">
        <input
          ref={ref}
          id={id}
          autoComplete={autoComplete}
          name={id}
          disabled={disabled}
          type={type}
          required
          placeholder={placeholder}
          className={twMerge(
            `peer w-full p-2 pl-3 outline-none dark:bg-transparent border-[1.5px] rounded-md transition text-zinc-900 disabled:cursor-not-allowed dark:text-white`,
            (errors?.[id] || errorCustomId)
              ? "border-rose-400 focus:border-rose-400"
              : "border-zinc-300 dark:border-gray-700 focus:border-[var(--color-primary)] valid:border-[var(--color-primary)]",
            className,
            disabled ? "bg-gray-200 border-[var(--color-primary)]" : "bg-white"
          )}
          {...props}
        />
        <label
          htmlFor={id}
          className={twMerge(
            `absolute flex items-center gap-1 justify-center dark:bg-transparent px-1 cursor-text text-sm duration-150 transform -top-[0.625rem] z-10 origin-[0] left-3 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-[12px] peer-focus:-top-[0.625rem] peer-focus:bg-white dark:peer-focus:bg-[var(--bg-secondary)] peer-focus:px-1 dark:peer-valid:bg-[var(--bg-secondary)]
        `,
            (errors?.[id] || errorCustomId)
              ? "text-rose-500"
              : (placeholder != "" && disabled)  ? "text-[var(--color-primary)]" : "text-zinc-700 dark:text-white peer-focus:text-[var(--color-primary)] peer-valid:text-[var(--color-primary)]",
            disabled ? "bg-gradient-to-b from-white to-gray-200" : "bg-white",
          )}
        >
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {iconRight}
        {(errors?.[id] || errorCustomId) && (
          <p className="text-rose-500 text-sm mt-2">
            {errors?.[id]?.message?.toString() || errorCustomId?.message?.toString()}
          </p>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
