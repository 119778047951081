import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import LOGO from "../assets/images/simpeel_ypl_white.png";
import SMLOGO from "../assets/images/small-cg.png";
import {
  ChevronLeft,
  ChevronDown,
  LayoutDashboard,
  Clock2,
  CalendarClock,
  UserRoundCog,
  CalendarOff,
  CalendarRange,
  FileClock,
  FileCheck2,
  Circle,
  FileCog,
  BriefcaseBusiness,
} from "lucide-react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { sidebarToggleService } from "../state/sidebarToggle";
import { Each, Show } from "../components"
import { cn } from "../helpers";

const SIDEBAR_ITEMS = [
  { id: "beranda", path: "/beranda", title: "Beranda", icon: LayoutDashboard },
  { id: "kalender", path: "/kalender", title: "Kalender", icon: CalendarRange },
  { id: "pegawai", path: "/pegawai", title: "Pegawai", icon: UserRoundCog },
]
const SIDEBAR_ITEMS_ATTENDANCE = [
  { id: "kehadiran", path: "/kehadiran", title: "Kehadiran", icon: FileCheck2 },
  // { id: "cuti", path: "/cuti", title: "Cuti", icon: CalendarOff },
  { id: "lembur", path: "/lembur", title: "Lembur", icon: FileClock },
];

const SIDEBAR_ITEMS_SETTING = [
  { id: "jam-kerja", path: "/jam-kerja", title: "Jam Kerja", icon: Clock2 },
  { id: "jadwal-kerja", path: "/jadwal-kerja", title: "Jadwal Kerja", icon: CalendarClock },
  { id: "jam-kerja-shift", path: "/jam-kerja-shift", title: "Jam Kerja Shift", icon: Clock2 },
  { id: "jadwal-kerja-shift", path: "/jadwal-kerja-shift", title: "Jadwal Kerja Shift", icon: CalendarClock },
  { id: "ketentuan-lembur", path:"/ketentuan-lembur", title: "Ketentuan Lembur", icon: FileCog },
  { id: "jenis-pekerjaan", path:"/jenis-pekerjaan", title:"Jenis Pekerjaan", icon:BriefcaseBusiness }
];

const MotionNavLink = motion.create(NavLink);

export default function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState(SIDEBAR_ITEMS[0].id);

  useEffect(() => {
    const subscription = sidebarToggleService.onToggle().subscribe(() => {
      setIsCollapsed((prevIsOpen) => !prevIsOpen);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <aside
      className={cn("sidebar max-w-[260px] z-[11]", isCollapsed && "collapsed")}
    >
      <div className={cn(isCollapsed ? "px-2.5 pb-0" : "px-4 pb-3")}>
        <div className="flex justify-between h-[70px] items-center">
          <Show>
            <Show.When isTrue={isCollapsed}>
              <img src={SMLOGO} alt="Brand Logo" />
            </Show.When>
            <Show.Else>
              <img src={LOGO} alt="Brand Logo" />
            </Show.Else>
          </Show>
        </div>
        <button
          className="sidebar__collapse-button mt-3"
          onClick={() => sidebarToggleService.toggle()}
        >
          <ChevronLeft
            size={24}
            className={clsx("transition-transform text-[var(--color-purple)]", {
              "rotate-180": isCollapsed,
            })}
          />
        </button>
      </div>
      <ul className="main">
        <Show>
          <Show.When isTrue={isCollapsed}>
            <li>
              <span className="sidebar-item">
                <Circle size={18} className="text-white cursor-default" />
              </span>
            </li>
          </Show.When>
          <Show.Else>
            <h3 className="text-gray-300 flex items-center ml-1 pb-1">Menu</h3>
          </Show.Else>
        </Show>
        <Each
          of={SIDEBAR_ITEMS}
          render={(item) =>
            <Show>
              <Show.When isTrue={item?.children}>
                <SidebarGroup
                  key={item.id}
                  activeTab={activeTab}
                  item={item}
                  setActiveTab={setActiveTab}
                />
              </Show.When>
              <Show.Else>
                <SidebarItem
                  key={item.id}
                  item={item}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Show.Else>
            </Show>
          }
        />
        <Show>
          <Show.When isTrue={isCollapsed}>
            <li>
              <span className="sidebar-item">
                <Circle size={18} className="text-white cursor-default" />
              </span>
            </li>
          </Show.When>
          <Show.Else>
            <h3 className="text-gray-300 flex items-center ml-1 py-1">Kehadiran</h3>
          </Show.Else>
        </Show>
        <Each
          of={SIDEBAR_ITEMS_ATTENDANCE}
          render={(item) =>
            <Show>
              <Show.When isTrue={item?.children}>
                <SidebarGroup
                  key={item.id}
                  activeTab={activeTab}
                  item={item}
                  setActiveTab={setActiveTab}
                />
              </Show.When>
              <Show.Else>
                <SidebarItem
                  key={item.id}
                  item={item}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Show.Else>
            </Show>
          }
        />
        <Show>
          <Show.When isTrue={isCollapsed}>
            <li>
              <span className="sidebar-item">
                <Circle size={18} className="text-white cursor-default" />
              </span>
            </li>
          </Show.When>
          <Show.Else>
            <h3 className="text-gray-300 flex items-center ml-1 py-1">Pengaturan</h3>
          </Show.Else>
        </Show>
        <Each
          of={SIDEBAR_ITEMS_SETTING}
          render={(item) =>
            <Show>
              <Show.When isTrue={item?.children}>
                <SidebarGroup
                  key={item.id}
                  activeTab={activeTab}
                  item={item}
                  setActiveTab={setActiveTab}
                />
              </Show.When>
              <Show.Else>
                <SidebarItem
                  key={item.id}
                  item={item}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Show.Else>
            </Show>
          }
        />
      </ul>
    </aside>
  );
}

function SidebarGroup({ activeTab, item, setActiveTab }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const IconComponent = item.icon;

  useEffect(() => {
    const subscription = sidebarToggleService.onToggle().subscribe(() => {
      setIsCollapsed((prevIsOpen) => !prevIsOpen);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <li>
        <MotionNavLink
          layout
          className={clsx("sidebar-item flex flex-col gap-2", {
            "sidebar-item__active": activeTab === item.id,
          })}
          onFocus={() => setActiveTab(item.id)}
          onMouseOver={() => setActiveTab(item.id)}
          onMouseLeave={() => setActiveTab(item.id)}
          onClick={() => {
            if (isCollapsed) {
              sidebarToggleService.toggle(true);
            }
            setSubMenuOpen(!subMenuOpen);
          }}
          title={isCollapsed ? item.title : null}
        >
          <div className="flex w-full items-center gap-2">
            {activeTab === item.id ? (
              <motion.div
                layoutId="sidebar-item-indicator"
                className="sidebar-item__active-bg"
              />
            ) : null}
            <span className="sidebar-item__icon">
              <IconComponent size={18} className="-mt-0.5" />
            </span>
            <motion.span
              className="sidebar-item__title w-full whitespace-nowrap"
              animate={{
                clipPath: isCollapsed
                  ? "inset(0% 100% 0% 0%)"
                  : "inset(0% 0% 0% 0%)",
                opacity: isCollapsed ? 0 : 1,
                width: isCollapsed ? "10%" : "100%",
              }}
            >
              {item.title}
            </motion.span>
            <span className="sidebar-item__icon">
              <ChevronDown
                size={18}
                className={clsx("transition-transform", {
                  "rotate-180": subMenuOpen,
                })}
              />
            </span>
          </div>
        </MotionNavLink>
      </li>
      <motion.ul
        animate={
          subMenuOpen && !isCollapsed
            ? {
                height: "fit-content",
              }
            : {
                height: 0,
              }
        }
        className="flex h-0 flex-col text-[0.8rem] font-normal w-full pl-3 overflow-hidden"
      >
        {item?.children?.map((child) => (
          <SidebarItem
            key={child.id}
            item={child}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            iconSize={15}
          />
        ))}
      </motion.ul>
    </>
  );
}
function SidebarItem({ item, activeTab, setActiveTab, iconSize = 18 }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const IconComponent = item.icon;

  useEffect(() => {
    const subscription = sidebarToggleService.onToggle().subscribe(() => {
      setIsCollapsed((prevIsOpen) => !prevIsOpen);
    });

    return () => subscription.unsubscribe();
  }, []);
  return (
    <li>
      <MotionNavLink
        layout
        className={clsx("sidebar-item", {
          "sidebar-item__active": activeTab === item.id,
        })}
        onFocus={() => setActiveTab(item.id)}
        onMouseOver={() => setActiveTab(item.id)}
        onMouseLeave={() => setActiveTab(item.id)}
        to={item.path}
        title={isCollapsed ? item.title : null}
      >
        {activeTab === item.id ? (
          <motion.div
            layoutId="sidebar-item-indicator"
            className="sidebar-item__active-bg"
          />
        ) : null}
        <span className="sidebar-item__icon">
          <IconComponent size={iconSize} className="-mt-0.5" />
        </span>
        <motion.span
          className="sidebar-item__title w-full whitespace-nowrap"
          animate={{
            clipPath: isCollapsed
              ? "inset(0% 100% 0% 0%)"
              : "inset(0% 0% 0% 0%)",
            opacity: isCollapsed ? 0 : 1,
          }}
        >
          {item.title}
        </motion.span>
      </MotionNavLink>
    </li>
  );
}
